<template>
  <span class="ui-timeline-item_label">{{text}}</span>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>
